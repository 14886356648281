export var state = {  
  canvas: null,
  ctx: null,
  windowWidth: null,
  rgbColor1: null,
  rgbColor2: null,
  rgbColor3: null,
  rgbColor4: null,
  rgbColor5: null,
  ship: null,
  hud: null,
  splash: null,
  buttons: [],
  asteroids: [],
  lasers: [],
  enemies: [],
  bosses: [],
  debris: [],
  pointNumbers: [],
  powerUps: [],
  stars: [],
  barriers: [],
  dust: [],
  canPlay: true,
  laserOverHeat: false,
  splashScreen: true,
  consoleTrigger: false,
  easeInStars: .75,
  powerUpCounter: 300,
  possibleEnemies: 1,
  possibleBarriers: 10,
  possibleBosses: 1,
  shieldTime: 180,
  score: 0,
  lives: 3,
  level: 0,
  laserCharge: 1270,
  beginGameSequence: 0
}